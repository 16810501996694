import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styles } from './../../styles';
import { NavLink } from 'react-router-dom';
import { Cross as Hamburger } from 'hamburger-react'
import { useLocation } from 'react-router-dom';
import { logo } from '../../assets';
import { LanguageContext } from '../../context/LanguageContext';


const Navbar = () => {

    const [active, setActive] = useState("");
    const [toggle, setToggle] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname;
    const { language, setLanguage } = useContext(LanguageContext);
    const [screenWidth, setScreenWidth] = useState(0);
    const { t, i18n } = useTranslation();


    const toggleLanguage = () => {
        const newLanguage = language === 'fr' ? 'en' : 'fr';
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
        return () =>
            window.removeEventListener("resize", () =>
                setScreenWidth(window.innerWidth)
            );
    }, []);



    useEffect(() => {

        const locationPattern = /^\/location\/([^/]+)$/;

        if (locationPattern.test(currentPath)) {
            setActive('location');
        } else if (currentPath === '/') {
            setActive('home');
        } else if (currentPath === '/addresses') {
            setActive('addresses');
        } else if (currentPath === '/journal') {
            setActive('journal');
        } else if (currentPath === '/contact') {
            setActive('contact');
        } else if (currentPath === '/about') {
            setActive('about');
        } else if (currentPath === '/press') {
            setActive('press');
        }
        setToggle(false);
    }, [location]);



    return (
        <nav className={`${styles.paddingX}  flex items-center fixed z-20`}>
            <div className={`justify-between w-full flex items-center max-w-7xl mx-auto`}>

                {/* LOGO */}
                {(currentPath !== '/') ? (
                    <Link
                        to="/"
                        className="flex items-center justify-center h-5 rounded-2xl py-10"
                        onClick={() => {
                            setActive("home");
                            window.scrollTo(0, 0);
                        }}>
                        <img src={logo} alt="logo" className="
                    sm:w-[105px]
                    sm:h-[105px]
                    w-[100px]
                    h-[50px]
                    object-contain" />
                    </Link>
                ) : null}


                {/* LIEN VISIBLE */}
                <ul className={` gap-5 flex items-center justify-center flex z-20 fixed top-0  right-[20px] sm:right-[50px] md:right-[60px] lg:right-[60px] py-4 `}>
                    <li className={`${!toggle ? styles.navLinkWhite : styles.navLinkActive} hover:text-white font-poppins text-[18px] font-medium cursor-pointer`}>
                        <a href="https://www.instagram.com/thehost.place/" target="_blank" rel="noopener noreferrer">
                            {t('navbar.insta')}
                        </a>
                    </li>
                    {/*<li className={`${!toggle ? styles.navLinkWhite : styles.navLinkActive}  hover:text-white font-poppins text-[18px] font-medium cursor-pointer`}>
                        <div onClick={toggleLanguage}>
                            {language === 'fr' ? t('navbar.fr') : t('navbar.en')}
                        </div>
                    </li>
                    <Hamburger color={`${!toggle ? '#FFFEFA' : '#FFFEFA'} `} toggled={toggle} alt='menu' toggle={setToggle} onToggle={() => { setToggle(!toggle) }} />
                    */}
                </ul>
            </div>
            {/* MENU */}
            {/*<div className={`${!toggle ? "hidden" : screenWidth < 600 ? "bg-[#FFFEFA] w-full" : "bg-[#FFFEFA] w-[520px]"} px-6 py-2 fixed top-0 right-0 z-10 h-screen`}>
                <ul className="list-none flex flex-col gap-4 justify-center h-full">
                    <li
                        key={'addresses'}
                        className={`${active === 'addresses' ? styles.thirdTextColor : styles.secondaryTextColor
                            } hover:text-[#5A5C20] cursor-pointer menu-link text-left w-full `}
                        onClick={() => {
                            setToggle(!toggle);
                            setActive('addresses');
                        }}
                    >
                        <NavLink to="addresses" >
                            {t('address.menu')}
                        </NavLink>
                    </li>
                    <li
                        key={'journal'}
                        className={`${active === 'journal' ? styles.thirdTextColor : styles.secondaryTextColor
                            } hover:text-[#5A5C20] cursor-pointer menu-link text-left w-full `}
                        onClick={() => {
                            setToggle(!toggle);
                            setActive('journal');
                        }}
                    >
                        <NavLink to="journal" >
                            {t('journal.title')}
                        </NavLink>
                    </li>
                    <li
                        key={'contact'}
                        className={`${active === 'contact' ? styles.thirdTextColor : styles.secondaryTextColor
                            } hover:text-[#5A5C20] cursor-pointer menu-link text-left w-full `}
                        onClick={() => {
                            setToggle(!toggle);
                            setActive('contact');
                        }}
                    >
                        <NavLink to="contact" >
                            {t('contact.title')}
                        </NavLink>
                    </li>
                    <li
                        key={'about'}
                        className={`${active === 'about' ? styles.thirdTextColor : styles.secondaryTextColor
                            } hover:text-[#5A5C20] cursor-pointer menu-link text-left w-full `}
                        onClick={() => {
                            setToggle(!toggle);
                            setActive('about');
                        }}
                    >
                        <NavLink to="about" >
                            {t('about.title')}
                        </NavLink>
                    </li>
                    <li
                        key={'press'}
                        className={`${active === 'press' ? styles.thirdTextColor : styles.secondaryTextColor
                            } hover:text-[#5A5C20] cursor-pointer menu-link text-left w-full `}
                        onClick={() => {
                            setToggle(!toggle);
                            setActive('press');
                        }}
                    >
                        <NavLink to="press" >
                            {t('press.title')}
                        </NavLink>
                    </li>
                </ul>
            </div>*/}
        </nav>
    )
}

export default Navbar
